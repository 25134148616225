import React, { useState, useEffect } from "react";
import {
  Card as BootstrapCard,
  CardBody,
  CardTitle,
  CardText,
} from "react-bootstrap";
import { TitleSection } from "../components";

const ServiceFullPage = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 996);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 996);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const serviceData = [
    {
      title: "Software Development",
      description:
        "At our company, we strive to exceed customer expectations with our software solutions by providing exceptional and timely services. We take pride in our ability to offer creative solutions, maintain integrity, and deliver projects on schedule. We assist clients in finding the best software solutions for their needs across a variety of industries.",
      imgName: "sde.jpeg",
    },
    {
      title: "Research and Development",
      description:
        "Our research and development (R&D) team is dedicated to pushing the boundaries of innovation. With a relentless pursuit of excellence, we explore emerging technologies and trends to develop groundbreaking solutions for our clients. From concept to execution, we leverage our expertise to deliver forward-thinking R&D services that drive growth and success.",
      imgName: "bussiness.jpeg",
    },
    {
      title: "IT Consultation",
      description:
        "Our IT consultation services provide personalized guidance and strategic insights to help clients navigate the complex digital landscape. With a deep understanding of industry trends and best practices, we empower businesses to make informed decisions and achieve their goals in the ever-evolving technology landscape.",
      imgName: "management.jpg",
    },
    {
      title: "Custom Management Software",
      description:
        "We specialize in designing and implementing custom management software solutions that streamline operations, enhance efficiency, and empower businesses with advanced tools for seamless integration and management. From project management to customer relationship management (CRM), our solutions are tailored to meet the unique needs of each client.",
      imgName: "support.jpeg",
    },
    {
      title: "Website Development",
      description:
        "Our website development services focus on creating immersive and user-centric websites that captivate audiences and drive results. With a keen eye for design and expertise in cutting-edge technologies, we deliver visually stunning websites that leave a lasting impression and help businesses thrive in the digital landscape.",
      imgName: "web.jpeg",
    },
  ];

  return (
    <>
      <TitleSection name="services"/>
      <div className="row bg-white">
        <div className="col-sm-12 col-md-12 col-lg-12 about-div2">
          <div className={`cards-container serviceCardGrid m-3`}>
            {serviceData.map((service, index) => (
              <ServiceCard
                key={index}
                active={index}
                title={service.title}
                description={service.description}
                isDesktop={isDesktop}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const ServiceCard = ({ active, title, description, isDesktop }) => {
  return (
    <>
      {isDesktop === true ? (
        active % 2 !== 0 ? (
          <>
            {" "}
            <BootstrapCard>
              <CardBody></CardBody>
            </BootstrapCard>
            <div class="grid-item center-line"></div>
            <BootstrapCard className="serviceCardBorder-right">
              <CardBody>
                <CardTitle
                  style={{
                    fontSize: "xx-large",
                  }}
                  className="serviceCardTitle-right"
                >
                  {title}
                </CardTitle>
                <CardText className="mt-3">{description}</CardText>
              </CardBody>
            </BootstrapCard>{" "}
          </>
        ) : (
          <>
            <BootstrapCard className="serviceCardBorder-left">
              <CardBody>
                <CardTitle
                  style={{
                    fontSize: "xx-large",
                  }}
                  className="serviceCardTitle-left"
                >
                  {title}
                </CardTitle>
                <CardText className="mt-3">{description}</CardText>
              </CardBody>
            </BootstrapCard>
            <div class="grid-item center-line"></div>
            <BootstrapCard>
              <CardBody></CardBody>
            </BootstrapCard>
          </>
        )
      ) : (
        <>
          {" "}
          <BootstrapCard className="serviceCardBorder-left">
            <CardBody>
              <CardTitle
                style={{
                  fontSize: "xx-large",
                }}
                className="serviceCardTitle-left"
              >
                {title}
              </CardTitle>
              <CardText className="mt-3">{description}</CardText>
            </CardBody>
          </BootstrapCard>
        </>
      )}
    </>
  );
};
export default ServiceFullPage;

import "./App.css";
import { Contactus, Service, Aboutus, Home, Career } from "./pages";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Routes>
        <>
          <Route path="/home" element={<Home />} />
          <Route path="/career" element={<Career />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/service" element={<Service />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="*" element={<Home />} />
        </>
      </Routes>
    </div>
  );
}

export default App;

import React, { useEffect, useRef, useState } from "react";

const Aboutus = () => {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const section = sectionRef.current;
    const sectionTop = section.getBoundingClientRect().top;
    const triggerPoint = window.innerHeight - 250; // Adjust this value as needed

    if (sectionTop < triggerPoint) {
      setIsVisible(true);
      window.removeEventListener("scroll", handleScroll); // Remove scroll event listener after the animation is triggered
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div id="aboutus">
      <br />
      <div
        className="row p-3"
        style={{
          background: "#5e0d7e",
        }}
      >
        <div
          className={`row aboutusCardsAnimate ${isVisible ? "visible" : ""} mx-0`}
          ref={sectionRef}
        >
          <div className="col-sm-12 col-lg-12 col-md-12">
            <div className="section-title text-white mt-2 mb-3">ABOUT US</div>
            <p
              className="text-white text-justify mt-2 mx-1"
              style={{ textAlign: "justify", color: "#e8e8e8 !important" }}
            >
              Welcome to Whiz Geek, where innovation meets practicality! As
              pioneers in research and development (R&D), we're dedicated to
              exploring the latest technologies, ensuring our clients remain at
              the forefront of progress. Our expertise lies in crafting bespoke
              management software tailored to the unique needs of businesses,
              startups, and institutes.
              <br />
              <br /> By seamlessly integrating cutting-edge technology, we drive
              growth and efficiency, empowering our clients to thrive in today's
              dynamic landscape. Understanding the diverse challenges faced by
              individuals and businesses, we offer comprehensive IT consultation
              services. Our personalized guidance navigates the complexities of
              the digital realm, empowering both budding entrepreneurs and
              established organizations to achieve their goals. <br />
              <br />
              At Whiz Geek, we're committed to your success. With our team by
              your side, you can embrace the future with confidence, knowing you
              have a partner dedicated to unlocking the full potential of
              technology. Together, let's shape a brighter tomorrow.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;

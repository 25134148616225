import React from "react";
import { NavbarCustom, CareerFullPage, Footer } from "../components";

const Career = () => {
  return (
    <>
      <NavbarCustom />
      <CareerFullPage />
      <Footer />
    </>
  );
};

export default Career;

import React from "react";

const ContactusFullPage = () => {
  return (
    <>
      <div className="row bg-white">
        <div
          className="col-sm-12 col-md-12 col-lg-12 "
          style={{
            textAlignLast: "center",
          }}
        >
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLScey5Rnv3DrF1ixKGmx3c3jvUpwlTHj1uzu38exs2B2TkYkXg/viewform?embedded=true"
            width="100%"
            height="1050px"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="Contact us"
          >
            Loading…
          </iframe>
        </div>
      </div>
    </>
  );
};

export default ContactusFullPage;

import React from "react";
import { ContactusFullPage, Footer, NavbarCustom } from "../components";

const Contactus = () => {
  return (
    <>
      <NavbarCustom />
      <ContactusFullPage />
      <Footer />
    </>
  );
};

export default Contactus;

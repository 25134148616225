import React from "react";
import {
  Card as BootstrapCard,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
} from "react-bootstrap";

const HighlightsCards = () => {
  const highlightData = [
    {
      title: "On Time Delivery",
      description:
        "We understand value of time and we are very specific about the deadline. We consistently work hard and deliver project on time.",
      imgName: "sde.jpeg",
    },
    {
      title: "Affordable Costing",
      description:
        "We help our client to achieve their full goal according to their budget.",
      imgName: "bussiness.jpeg",
    },
    {
      title: "Support",
      description:
        "We believe no business can run without trust and we strictly follow our morality of trusted client relationships that are beyond contract or technology.",
      imgName: "management.jpg",
    },
  ];

  return (
    <div id="highlights">
      <br />
      <h3 className="section-title">WHY WHIZ GEEK ?</h3>
      <div className="carousel-container">
        <div className="cards-container highlightsCards">
          {highlightData.map((service, index) => (
            <HighlightsCard
              key={index}
              title={service.title}
              description={service.description}
              imgName={service.imgName}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const HighlightsCard = ({ active, title, description, imgName }) => {
  return (
    <BootstrapCard style={{ width: "18rem" }} className="activeCard">
      <CardImg
        variant="top"
        src={require(`../assets/images/cards/${imgName}`)}
        style={{ height: " 150px", maxHeight: " 150px" }}
        rounded
      />
      <CardBody>
        <CardTitle style={{ fontSize: "smaller !important", color: "purple" }}>
          {title}
        </CardTitle>
        <CardText>{description}</CardText>
      </CardBody>
    </BootstrapCard>
  );
};

export default HighlightsCards;

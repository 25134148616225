const ApplicationFormSection = (props) => {
  return (
    <div
      className="row bg-white"
      style={{
        minHeight: "12rem",
      }}
    >
      <div
        className="col-sm-12 col-md-12 col-ld-12 mt-3"
        style={{
          alignSelf: "center",
        }}
      >
        <div className="text-center h5">
          {" "}
          Internship Application Link :{" "}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdh6RCUuG6tBBTpvwQ6iTna-4R1A7j6SSkZBjw6rNsffpkdmA/viewform"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Click Here{" "}
          </a>{" "}
        </div>
      </div>
    </div>
  );
};

export default ApplicationFormSection;

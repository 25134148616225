import Carousel from "react-bootstrap/Carousel";

const CarouselFade = () => {
  return (
    <div id="home">
      <Carousel fade>
        <Carousel.Item>
          <img
            src={require("../assets/images/cover/cover.png")}
            alt="First slide"
            width={"100%"}
            style={{ height: "30%", maxHeight: "500px" }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={require("../assets/images/cover/cover1.png")}
            alt="Second slide"
            width={"100%"}
            style={{ height: "30%", maxHeight: "500px" }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={require("../assets/images/cover/cover2.png")}
            alt="Third slide"
            width={"100%"}
            style={{ height: "30%", maxHeight: "500px" }}
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default CarouselFade;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div id="contactus">
      <div
        className="row p-3"
        style={{
          background: "linear-gradient(180deg, #fff 0.1%, #000 3%)",
        }}
      >
        <div
          className="col-sm-7 col-lg-7 col-md-7"
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <iframe
            title="location"
            width="100%"
            height="450"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://maps.google.com/maps?width=450&amp;height=350&amp;hl=en&amp;q=12.9026676,77.5428045+(Whiz%20Geek)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </div>
        <div className="col-sm-5 col-lg-5 col-md-5">
          <p className="section-title text-white h3">Get In Touch</p>
          <hr style={{ color: "#fff", backgroundColor: "#fff", height: 2 }} />
          <div className=" text-white ">
            <p
              style={{
                lineHeight: "30px",
                cursor: "default",
              }}
            >
              <FontAwesomeIcon icon={faEnvelope} /> team@whizgeek.in
              <br />
              <FontAwesomeIcon icon={faPhone} /> +91 9845883192
              <br />
              <FontAwesomeIcon icon={faMapMarkerAlt} /> Uttarahalli, Bangalore-560061
            </p>
            <br />
            <p
              className="section-title text-white h5 text-capitalize"
              style={{
                align: "left !important",
                float: "left",
                display: "contents",
              }}
            >
              Opening Hours
            </p>
            <div
              style={{
                lineHeight: "12px",
                marginTop: "15px",
                fontSize: "smaller",
              }}
            >
              <p className=""> Mon - Fri 10:00 am - 8:00 pm</p>
              <p className=""> Saturday 9:00 am - 5:00 pm</p>
              <p className="">Sunday 9:00 am - 12:00 pm</p>
            </div>
          </div>
          <hr
            style={{
              color: "#fff",
              backgroundColor: "#fff",
              height: 2,
              marginTop: "125px",
            }}
          />
          <p
            className=""
            style={{
              fontSize: "smaller",
              marginTop: "-9px",
              textAlignLast: "center",
              color: "#404040",
            }}
          >
            Designed & Developed by Whiz Geek @ 2024
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

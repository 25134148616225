import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const NavbarCustom = () => {
  const [navbarColor, setNavbarColor] = useState("#5e0d7e");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 2) {
        setNavbarColor("#00000094");
      } else {
        setNavbarColor("#5e0d7e");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar
      style={{
        backgroundColor: `${navbarColor}`,
      }}
      data-bs-theme="dark"
      collapseOnSelect
      sticky="top"
      expand="lg"
      className="navbar-bg"
    >
      <Container fluid>
        <Navbar.Brand href="/home">Whiz Geek</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav navbarScroll">
          <Nav className="me-auto my-2 my-lg-0 navbar-scroll-item" navbarScroll>
            <Nav.Link href="/home">Home</Nav.Link>
            <Nav.Link href="/service">Services</Nav.Link>
            <Nav.Link href="/aboutus">About Us</Nav.Link>
            {/* <Nav.Link href="/highlights">Why Choose Us</Nav.Link> */}
            <Nav.Link href="/career">Career</Nav.Link>
            <Nav.Link href="/contactus">Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarCustom;

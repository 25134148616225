const TitleSection = (props) => {
  return (
    <div className="row bg-white">
      <div className="col-sm-12 col-md-12 col-ld-12  mt-3">
        <div
          className="h3 text-uppercase text-center page-title"
        >
          {props.name}
        </div>
      </div>
    </div>
  );
};

export default TitleSection
import React from "react";
import TitleSection from "../components/TitleSection";
import { ApplicationFormSection } from "../components";

const CareerFullPage = () => {
  return (
    <>
      <TitleSection name="Career" />
      <div className="row bg-white">
        <div className="col-sm-12 col-md-12 col-lg-12 ">
          <ApplicationFormSection />
        </div>
      </div>
    </>
  );
};

export default CareerFullPage;
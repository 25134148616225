import React from "react";
import {
  Card as BootstrapCard,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
} from "react-bootstrap";
import { TitleSection } from "../components";

const AboutusFullPage = () => {
  const company_data = [
    {
      title: "Vision",
      description:
        "To turn incredible dreams into high-quality, real-world products.",
      imgName: "vision.png",
    },
    {
      title: "Mission",
      description: "To help people bring their ideas into reality",
      imgName: "mission.png",
    },
    {
      title: "Goals",
      description: "Keep it simple and realistic with high standards.",
      imgName: "goal.png",
    },
  ];

  return (
    <>
      <TitleSection name="about us" />
      <div className="row bg-white">
        <div className="col-sm-12 col-md-7 col-lg-5 about-div1">
          <span className="about-sub-title px-5 pb-2">
            {" "}
            Welcome to Whiz Geek, where innovation meets practicality! As
            pioneers in research and development (R&D), we're dedicated to
            exploring the latest technologies, ensuring our clients remain at
            the forefront of progress. Our expertise lies in crafting bespoke
            management software tailored to the unique needs of businesses,
            startups, and institutes.
            <br />
            <br />
            By seamlessly integrating cutting-edge technology, we drive growth
            and efficiency, empowering our clients to thrive in today's dynamic
            landscape. Understanding the diverse challenges faced by individuals
            and businesses, we offer comprehensive IT consultation services. Our
            personalized guidance navigates the complexities of the digital
            realm, empowering both budding entrepreneurs and established
            organizations to achieve their goals.
            <br /> <br />
            At Whiz Geek, we're committed to your success. With our team by your
            side, you can embrace the future with confidence, knowing you have a
            partner dedicated to unlocking the full potential of technology.
            Together, let's shape a brighter tomorrow.
            <br /> <br />
            <div className="d-inline-grid">
              <span className="font-weight-bold">
                <strong>Chandru Vinayagam</strong>
              </span>
              <span>Sole Properitor, Head of Tech and Products</span>
              <span>Whiz Geek</span>
            </div>
          </span>
        </div>
        <div className="col-sm-12 col-md-5 col-lg-7 about-div2">
          <img
            src={require(`../assets/images/graphics/roadmap.png`)}
            className="about-cover"
            alt="roadmap"
          />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 about-div2">
          <div id="vmg">
            <br />
            <div className="carousel-container">
              <div className="cards-container vmgCards text-center">
                {company_data.map((service, index) => (
                  <CompanyVMGCards
                    key={index}
                    title={service.title}
                    description={service.description}
                    imgName={service.imgName}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CompanyVMGCards = ({ title, description, imgName }) => {
  return (
    <BootstrapCard style={{ width: "18rem" }} className="activeCard">
      <CardImg
        variant="top"
        src={require(`../assets/images/graphics/${imgName}`)}
        style={{
          height: "130px",
          maxHeight: "150px",
          width: "50%",
          alignSelf: "center",
        }}
        alt="vmg"
        rounded
      />
      <CardBody className="mt-2">
        <CardTitle style={{ fontSize: "smaller !important", color: "purple" }}>
          {title}
        </CardTitle>
        <CardText className="card-text text-center">{description}</CardText>
      </CardBody>
    </BootstrapCard>
  );
};
export default AboutusFullPage;

import React from "react";
import { AboutusFullPage, Footer, NavbarCustom } from "../components";

const Aboutus = () => {
  return (
    <>
      <NavbarCustom />
      <AboutusFullPage />
      <Footer />
    </>
  );
};

export default Aboutus;

import React from "react";
import { NavbarCustom, ServiceFullPage, Footer } from "../components";

const Service = () => {
  return (
    <>
      <NavbarCustom />
      <ServiceFullPage />
      <Footer />
    </>
  );
};

export default Service;

import React from "react";
import {
  NavbarCustom,
  CarouselFade,
  ServiceCards,
  Aboutus,
  HighlightsCards,
  Footer,
} from "../components";

const Home = () => {
  return (
    <>
      <NavbarCustom />
      <CarouselFade />
      <ServiceCards />
      <Aboutus />
      <HighlightsCards />
      <Footer />
    </>
  );
};

export default Home;
